import React from 'react'
import "./ThirdPage.css"

export default function ThirdPage() {
  return (
     <section className="ai-section" style={{backgroundColor: "black"}}>
          <div className="ai-heading">
              <span>improving at every stage with</span>
            <h2>Ai+ Human Expert</h2>
          </div>
          <div className="ai-para">
          <p>Got a low Blueprint score? No worries. Schedule a call with our
              expert Blueprint writers, refine your strategy, and elevate your
              Blueprint's impact. Your success is just a conversation away with
              IdeaEngine. </p>
          </div>
          <div className="ai-bg">
            <img src="ai-banner.png" alt="" />
          </div>
        </section>
  );
}
